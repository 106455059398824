<template>
  <v-app>
    <div>
      <KTHeaderMobile></KTHeaderMobile>
      <KTHeader></KTHeader>

      <div>
        <transition name="fade-in-up">
          <div style="margin-top: 65px">
            <div class="container px-0">
              <KTSubheader
                class="mb-0"
                v-if="subheaderDisplay"
                v-bind:breadcrumbs="breadcrumbs"
                v-bind:title="pageTitle"
              />
            </div>

            <router-view :key="$route.fullPath" />
          </div>
        </transition>
      </div>
      <div class="spacer" style="height: 5em"></div>
      <KTFooter class="trainee-footer"></KTFooter>
    </div>

    <!--    <div class="d-flex flex-column flex-root">-->
    <!--      &lt;!&ndash; begin:: Header Mobile &ndash;&gt;-->
    <!--      <KTHeaderMobile></KTHeaderMobile>-->
    <!--      &lt;!&ndash; end:: Header Mobile &ndash;&gt;-->

    <!--      <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>-->

    <!--      &lt;!&ndash; begin::Body &ndash;&gt;-->
    <!--      <div class="d-flex flex-row flex-column-fluid page">-->
    <!--        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">-->
    <!--          &lt;!&ndash; begin:: Aside Left &ndash;&gt;-->
    <!--          <KTAside v-if="asideEnabled"></KTAside>-->
    <!--          &lt;!&ndash; end:: Aside Left &ndash;&gt;-->
    <!--          &lt;!&ndash; begin:: Header &ndash;&gt;-->
    <!--          <KTHeader></KTHeader>-->
    <!--          &lt;!&ndash; end:: Header &ndash;&gt;-->

    <!--          &lt;!&ndash; begin:: Content &ndash;&gt;-->
    <!--          <div-->
    <!--            id="kt_content"-->
    <!--            class="content d-flex flex-column flex-column-fluid"-->
    <!--          >-->
    <!--            &lt;!&ndash; begin:: Content Head &ndash;&gt;-->
    <!--            <KTSubheader-->
    <!--              v-if="subheaderDisplay"-->
    <!--              v-bind:breadcrumbs="breadcrumbs"-->
    <!--              v-bind:title="pageTitle"-->
    <!--            />-->
    <!--            &lt;!&ndash; end:: Content Head &ndash;&gt;-->

    <!--            &lt;!&ndash; begin:: Content Body &ndash;&gt;-->
    <!--            <div class="d-flex flex-column-fluid">-->
    <!--              <div-->
    <!--                :class="{-->
    <!--                  'container-fluid': contentFluid,-->
    <!--                  container: !contentFluid,-->
    <!--                }"-->
    <!--              >-->
    <!--                <transition name="fade-in-up">-->
    <!--                  <router-view />-->
    <!--                </transition>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <KTFooter></KTFooter>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>-->
    <!--      <KTScrollTop></KTScrollTop>-->
    <!--    </div>-->
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import HtmlClass from "@/core/services/htmlclass.service";
// import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
// import KTScrollTop from "@/view/layout/extras/ScrollTop";
// import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/modules/htmlclass.module.js";
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/modules/config.module";

export default {
  name: "Layout",
  components: {
    // KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    // KTScrollTop,
    // Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutPublicConfig());
  },
  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    // check if current user is authenticated

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutPublicConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutPublicConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutPublicConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutPublicConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutPublicConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutPublicConfig("toolbar.self.display");
      // return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutPublicConfig("subheader.display");
    },
    subheaderClasses() {
      const classes = [];
      const style = this.layoutPublicConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutPublicConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
};
</script>
<style scoped lang="scss">
.trainee-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
</style>
